<template>
  <div class="grid-container pt-30">
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 medium-6 medium-offset-3">
        <h1>Dash - Request Password</h1>
      </div>
    </div>
    <div class="grid-x grid-padding-x pt-3">
      <div class="cell small-12 medium-6 medium-offset-3">
        <form @submit.prevent="sendLink">
          <label>Email
            <input
              aria-describedby="exampleHelpText"
              name="email"
              placeholder="email"
              type="text"
              v-model="email">
          </label>
          <p class="help-text" id="exampleHelpText">Please enter your email address</p>
          <div class="pt-3">
            <button class="button">
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
    <app-message
      v-if="showMessage === true"
      :messageText="message"
      :link="false"
      :linkText="false"
      @closeMessage="showMessage = false" />
  </div>
</template>

<script>
import Message from '@/components/messages/Message.vue';
import axios from '../../axios';

export default {
  name: 'RequestPasswordReset',
  components: {
    appMessage: Message,
  },
  data() {
    return {
      email: '',
      message: '',
      showMessage: false,
    };
  },
  methods: {
    sendLink() {
      const postData = {};
      postData.email = this.email;
      axios.post('/users/requestPasswordReset.json', postData)
        .then((response) => {
          this.showMessage = true;
          this.message = response.data.message;
        });
    },
  },
};
</script>
